import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";
import { faq } from "../context/ConstantEN";

export default function SubPagePcResourceEN(props) {
  const [searchKey, setSearchKey] = useState("");
  const [showList, setShowList] = useState(faq);
  const [foundAny, setFoundAny] = useState(true);

  const clearSearch = () => {
    setSearchKey((searchKey) => "");
    setFoundAny(true);
    setShowList(faq);
  };

  const startSearch = () => {
    const newItems = faq.filter((el) => el.q.includes(searchKey) || el.a.includes(searchKey));
    if (newItems.length > 0) {
      setShowList(newItems);
      setFoundAny(true);
    } else {
      setShowList([]);
      setSearchKey("");
      setFoundAny(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newItems = faq.filter((el) => el.q.includes(searchKey) || el.a.includes(searchKey));
      if (newItems.length > 0) {
        setShowList(newItems);
        setFoundAny(true);
      } else {
        setShowList([]);
        setSearchKey("");
        setFoundAny(false);
      }
    }
  };

  return (
    <div className="pcBody">
      <Helmet>
        <title>Any user who registers studio first time could win 90 Man-days for free</title>
        <meta
          name="description"
          content="Mobile users need to download APP first; Users may search Help documents in sf2Pro,
          read training materials released by sf2Pro from time to time or create inquiries to sf2Pro Helpdesk"
        />
      </Helmet>
      <div className="pcResourceSection0"></div>

      <div className="pcResourceSection1">
        <div className="secDescription5">
          <p className="secDescription5text">
            Any user who registers studio <span className="secTitle">first time</span> could win 90 Man-days in studio
            balance for free
            <br />
            <br />
          </p>

          <a href="https://sf.yige.world/register" target={"_blank"} rel="noreferrer" id="userRegisterS5">
            Register on PC
          </a>

          <p className="secDescription5text">
            For <span className="secTitle">mobile </span> user please download APP as following
          </p>

          <div className="secDesc5center">
            <a
              href={
                "https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/apk/sf2Pro_SunflowerToProfessionals_latest.apk"
              }
              target={"_blank"}
              rel="noreferrer"
              id="userDownloadS5android"
              download
            >
              Android Download
            </a>

            <a
              href="https://apps.apple.com/cn/app/%E9%A1%B9%E6%97%A5%E8%91%B5/id6474719288"
              target={"_blank"}
              rel="noreferrer"
              id="userDownloadS5ios"
            >
              iOS Download
            </a>
          </div>
        </div>

        <div className="pcDescResource1a">
          <div className="qrCodeDownloadAndroid">
            <img
              className="pcPicResource1a"
              src={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/qrcode/qrCodeAndroid.png"}
              alt="Download sf2Pro"
            ></img>
            <p className="secDescriptionS">Android</p>
          </div>
          <div className="qrCodeDownloadIos">
            <img
              className="pcPicResource1a"
              src={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/qrcode/qrCodeIOS.png"}
              alt="Download sf2Pro"
            ></img>
            <p className="secDescriptionS">iOS</p>
          </div>
        </div>

        <img className="pcPicResource1b" src={require("../assets/networkS.jpg")} alt="sf2Pro network"></img>
      </div>

      <div className="pcResourceSection2">
        <h3 className="secTitleLeft">Documents</h3>

        <div className="pcResourceSectionMat">
          <a
            className="pcDescResource2b"
            href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroductionEN.pdf"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img className="pcPicResource2a" src={require("../assets/document.png")} alt="Product Overview"></img>
            <p className="pcDirectInline">Overview-PPT</p>
          </a>

          <a
            className="pcDescResource2b"
            href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfIntroductionEN.mp4"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img className="pcPicResource2a" src={require("../assets/document.png")} alt="Product Overview"></img>
            <p className="pcDirectInline">Overview-MP4</p>
          </a>

          <a
            className="pcDescResource2b"
            href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SfTechnicalSpecEN.pdf"}
            target={"_blank"}
            rel="noreferrer"
          >
            <img className="pcPicResource2a" src={require("../assets/document.png")} alt="Technical Spec"></img>
            <p className="pcDirectInline">Technical Spec</p>
          </a>
        </div>
      </div>

      <div className="pcResourceSection3">
        <div className="pcDescResource3a">
          <h3 className="secTitle">sf2Pro Help</h3>
          <p className="secDescription">
            Users may search Help documents in sf2Pro,
            <br />
            read training materials released by sf2Pro from time to time, or consult sf2Pro Helpdesk
          </p>
        </div>

        <img className="pcPicResource3a" src={require("../assets/sfScreenHelpPnEN.png")} alt="My network"></img>

        <div className="pcDescResource3b">
          <h3 className="secTitleRight">sf2Pro Always Accompanies You</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            For any topic users are interested in, sf2Pro would try all possible ways e.g. live streaming, to interact
            with users.
          </p>
        </div>

        <img className="pcPicResource3b" src={require("../assets/sfScreenHelpPcEN.png")} alt="Studio list"></img>
      </div>

      <div className="pcResourceSectionQ">
        <h3 className="secTitle">Frequently Asked Questions</h3>
        <div className="pcFaqSearch">
          <input
            className="pcFaqSearchInput"
            type={"text"}
            placeholder={foundAny ? "Keywords" : "No record found, please retry"}
            value={searchKey}
            onChange={(input) => setSearchKey(input.target.value)}
            onKeyDown={handleKeyDown}
          ></input>
          {(!!searchKey || !foundAny) && (
            <img
              className="iconSectionQundo"
              src={require("../assets/undo.png")}
              alt="Cancel"
              onClick={clearSearch}
            ></img>
          )}
          <img
            className="iconSectionQsearch"
            src={require("../assets/searchRight.png")}
            alt="Search"
            onClick={startSearch}
          ></img>
        </div>
        <div className="pcFaqItems">
          {showList.map((el) => {
            return (
              <div key={el.q}>
                <p className="faqQuestion">{el.q}</p>
                <p className="faqAnswer">{el.a}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
