import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePcProductEN(props) {
  return (
    <div className="pcBody">
      <Helmet>
        <title>Understand sf2Pro by Business Scenarios</title>
        <meta
          name="description"
          content="Typical sf2Pro scenarios include: Setup Team, Define Business Rules, Start Delivery, Monitor Status, Review and Analysis and Studio Management"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,Yige"
        />
      </Helmet>
      <div className="pcProdSection0">
        <h3 className="secTitle">sf2Pro - Sunflower to Professionals - Scenario Illustration</h3>
        <p className="secDescription">Understand sf2Pro by Business Scenarios</p>
      </div>

      <div className="pcProdSection1">
        <div className="pcDescProd1a">
          <h3 className="secTitle">Scenario 1: Setup Team</h3>
          <p className="secDescription">
            Team setup starts from studio registration. Studio Admin might invite clients, suppliers, colleagues or
            supervisors to join his studio.
          </p>
        </div>

        <img className="pcPicProd1a" src={require("../assets/sfScreenInviteEN.png")} alt="Team setup"></img>

        <div className="pcDescProd1b">
          <p className="secDescription">
            Team members could be contract parties (e.g., consultants, lawyers and clients) or those within
            organizations (e.g., product developers, marketing analysts, sales, delivery engineers).
          </p>
        </div>

        <img className="pcPicProd1b" src={require("../assets/sfScreenRmRegistAEN.png")} alt="Team invitation"></img>
        <div className="pcDescProd1c">
          <h3 className="secTitleRight">Trust Built in Open Space</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro interlinks Demand and Supply so that both parties could communicate and always put things on table.
            sf2Pro commits to build clean working space for team to solve known service pain points, such as
            misunderstanding, obfuscation, unwritten rules and lacking of trust.
          </p>
        </div>
      </div>

      <div className="pcProdSection2">
        <div className="pcDescProd2a">
          <h3 className="secTitle">Scenario 2: Define Business Rules</h3>
          <p className="secDescription">
            Service team has a headache on obfuscation and mutual prevaricating. Demand and Supply parties often blame
            each other. sf2Pro enables deliverable evaluation mechanism. The evaluator might be internal or external
            expert, relevant evaluation type might be official or referential.
          </p>
        </div>

        <img className="pcPicProd2a" src={require("../assets/sfScreenConfigEN.png")} alt="Business rule"></img>

        <div className="pcDescProd2b">
          <h3 className="secTitleRight">Game Rules Matter with Teamworking</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro has defined templates for 21 service divisions (refer to division list in studio registration).
            Relevant studio configs include:
            <br />
            <br />
            Consensus items on collaboration
            <br />
            Criteria on deliverable evaluation
            <br />
            Rules on auto risk identification
            <br />
            Rules on performance calculation
            <br />
          </p>
        </div>
        <img className="pcPicProd2b" src={require("../assets/sfScreenConfigDetailEN.png")} alt="Studio config"></img>
      </div>

      <div className="pcProdSection3">
        <div className="pcDescProd3a">
          <h3 className="secTitle">Scenario 3: Start Delivery</h3>
          <p className="secDescription">
            Requirements and solutions are expanded with various tasks, issues and risks.
            <br />
            <br />
            Service practitioners ask for precise information classification and utilization. sf2Pro leverages
            multi-media data structure to:
            <br />
            <br />
            - Avoid ambiguous object descriptions
            <br />
            - Improve data input efficiency
            <br />
            <br />
            Meanwhile, full version management is applied to help team track object histories and minimize conflicts
            caused by unreliable memories otherwise.
          </p>
        </div>

        <img
          className="pcPicProd3a"
          src={require("../assets/sfScreenObjCreateAEN.png")}
          alt="Create business document"
        ></img>
        <img className="pcPicProd3b" src={require("../assets/sfScreenReqCreateAEN.png")} alt="Create requirement"></img>
      </div>

      <div className="pcProdSection4">
        <div className="pcDescProd4a">
          <h3 className="secTitle">Scenario 4: Monitor Status</h3>
          <p className="secDescription">
            sf2Pro distributes information real time in details. All stakeholders, e.g., task owners, issue responsible
            users, would be synchronized with status updates.
          </p>
        </div>

        <img className="pcPicProd4a" src={require("../assets/sfScreenTaskEN.png")} alt="Task overview"></img>

        <div className="pcDescProd4b">
          <h3 className="secTitleRight">Hold Business on Association</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            One requirement might be associated with multiple solutions or vise versa. One solution might be realized by
            several tasks. Some risks might be identified if they impact specific requirements or solutions.
            <br />
            <br />
            sf2Pro leverages network to manage service objects and their associations. Auto alerts might be triggered
            and sent to stakeholders when pre-defined conditions have been met.
          </p>
        </div>

        <img
          className="pcPicProd4b"
          src={require("../assets/sfScreenReqDisplayAEN.png")}
          alt="Display requirement"
        ></img>
      </div>

      <div className="pcProdSection5">
        <div className="pcDescProd5a">
          <h3 className="secTitle">Scenario 5: Review and Analysis</h3>
          <p className="secDescription">
            sf2Pro reports object statuses in Kanbans. Historical statuses could be tracked as well thanks to full
            version management applied.
          </p>
        </div>

        <img className="pcPicProd5a" src={require("../assets/sfScreenDoAEN.png")} alt="Objective DOA"></img>

        <div className="pcDescProd5b">
          <h3 className="secTitleRight">Team Compose Their Own Songs</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro presents team performance and fluctuation with music notes. The higher is music note, the better is
            team DOA (Degree of Achievement). sf2Pro calculates DOAs on rules which are defined by Admin in Studio
            Config.
            <br />
            <br />
            Each member could be evaluated by external expert (called as Guest in sf2Pro) as well as internal SME. Fair
            feedbacks per evaluators would be surely beneficial to team growth.
          </p>
        </div>

        <img className="pcPicProd5b" src={require("../assets/sfScreenIssueListEN.png")} alt="Issue list"></img>
      </div>

      <div className="pcProdSection6">
        <div className="pcDescProd6a">
          <h3 className="secTitle">Scenario 6: Studio Management</h3>
          <p className="secDescription">
            sf2Pro provides team safe and isolated digital spaces.
            <br />
            <br />
            Team members could join one or more studios. Studio Admin could invite new members, close studios or
            transfer studio balances. Authorized users could post team notices or newsletters.
          </p>
        </div>

        <img className="pcPicProd6a" src={require("../assets/sfScreenMyNetworkAEN.png")} alt="My network"></img>

        <div className="pcDescProd6b">
          <h3 className="secTitleRight">Network is Your Fortune</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro helps you maintain your networks. The network nodes include studio members who ever worked as your
            demand/supply parties, evaluators/evaluatees or risk/objective sharers.
          </p>
        </div>

        <img className="pcPicProd6b" src={require("../assets/sfScreenRoomListEN.png")} alt="Studio list"></img>
        <p
          className="pcDirect"
          style={{ position: "absolute", right: 0, bottom: 0 }}
          onClick={() => props.goResource()}
        >
          Register sf2Pro
        </p>
      </div>
    </div>
  );
}
