import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnProduct(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>Understand sf2Pro by Business Scenarios</title>
        <meta
          name="description"
          content="Typical sf2Pro scenarios include: Setup Team, Define Business Rules, Start Delivery, Monitor Status, Review and Analysis and Studio Management"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,Yige"
        />
      </Helmet>
      <div className="pnDescMainLeft">
        <h3 className="secTitlePnLeft">sf2Pro Scenario Illustration</h3>
        <p className="secDescriptionPnLeft">Understand sf2Pro by Business Scenarios</p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div className="pnWrapperRowInside">
          <div style={{ width: "45%" }}>
            <h3 className="secTitlePnLeft">Scenario 1: Setup Team</h3>
            <p className="secDescriptionPnLeft">
              Team setup starts from studio registration. Studio Admin might invite clients, suppliers, colleagues or
              supervisors to join his studio.
            </p>
          </div>
          <img
            style={{ width: "50%", borderRadius: 16 }}
            src={require("../assets/sfScreenRmRegistApnEN.png")}
            alt="Setup team"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          Team members could be contract parties (e.g., consultants, lawyers and clients) or those within organizations
          (e.g., product developers, marketing analysts, sales, delivery engineers).
        </p>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Trust Built in Open Space</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro interlinks Demand and Supply so that both parties could communicate and always put things on table.
          </p>
        </div>

        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenInviteEN.png")}
          alt="Setup team"
        ></img>
        <p className="secDescriptionPnLeft">
          sf2Pro commits to build clean working space for team to solve known service pain points, such as
          misunderstanding, obfuscation, unwritten rules and lacking of trust.
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 2: Define Business Rules</h3>
          <p className="secDescriptionPnLeft">
            Service team has a headache on obfuscation and mutual prevaricating. Demand and Supply parties often blame
            each other.
          </p>
        </div>
        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 16 }}
          src={require("../assets/sfScreenConfigEN.png")}
          alt="Business rule"
        ></img>
        <p className="secDescriptionPnLeft">
          sf2Pro enables deliverable evaluation mechanism. The evaluator might be internal or external expert, relevant
          evaluation type might be official or referential.
        </p>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Game Rules Matter with Teamworking</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro has defined templates for 21 service divisions (refer to division list in studio registration).
            Relevant studio configs include:
          </p>
        </div>

        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenConfigDetailEN.png")}
          alt="Business rule"
        ></img>
        <p className="secDescriptionPnLeft">
          <br />
          Consensus items on collaboration
          <br />
          Criteria on deliverable evaluation
          <br />
          Rules on auto risk identification
          <br />
          Rules on performance calculation
          <br />
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div className="pnWrapperRowInside">
          <div style={{ width: "45%" }}>
            <h3 className="secTitlePnLeft">Scenario 3: Start Delivery</h3>
            <p className="secDescriptionPnLeft">
              Requirements and solutions are expanded with various tasks, issues and risks.
            </p>
          </div>
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenReqCreateApnEN.png")}
            alt="Create requirement"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          Service practitioners ask for precise information classification and utilization. sf2Pro leverages multi-media
          data structure to:
          <br />
          <br />
          - Avoid ambiguous object descriptions
          <br />
          - Improve data input efficiency
          <br />
        </p>
        <div className="pnWrapperRowInside" style={{ alignItems: "flex-start" }}>
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenObjCreateApnEN.png")}
            alt="Create business document"
          ></img>
          <div style={{ width: "45%" }}>
            <p className="secDescriptionPnLeft">
              Meanwhile, full version management is applied to help team track object histories and minimize conflicts
              caused by unreliable memories otherwise.
            </p>
          </div>
        </div>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 4: Monitor Status</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro distributes information real time in details. All stakeholders, e.g., task owners, issue responsible
            users, would be synchronized with status updates.
          </p>
        </div>
        <img
          style={{ width: "70%", marginTop: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenTaskEN.png")}
          alt="Task overview"
        ></img>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div className="pnWrapperRowInside">
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenReqDisplayApnEN.png")}
            alt="Display requirement"
          ></img>
          <div style={{ width: "50%" }}>
            <h3 className="secTitlePnRight">Hold Business on Association</h3>
            <p className="secDescriptionPnLeft">
              One requirement might be associated with multiple solutions or vise versa. One solution might be realized
              by several tasks. Some risks might be identified if they impact specific requirements or solutions.
            </p>
          </div>
        </div>
        <p className="secDescriptionPnLeft">
          sf2Pro leverages network to manage service objects and their associations. Auto alerts might be triggered and
          sent to stakeholders when pre-defined conditions have been met.
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 5: Review and Analysis</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro reports object statuses in Kanbans. Historical statuses could be tracked as well thanks to full
            version management applied.
          </p>
        </div>
        <img
          style={{ width: "90%", marginTop: 16, borderRadius: 16 }}
          src={require("../assets/sfScreenDoAEN.png")}
          alt="Objective DOA"
        ></img>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div className="pnWrapperRowInside">
          <div style={{ width: "40%" }}>
            <h3 className="secTitlePnLeft">Team Compose Their Own Songs</h3>
            <p className="secDescriptionPnLeft">sf2Pro presents team performance and fluctuation with music notes.</p>
          </div>
          <img
            style={{ width: "55%", borderRadius: 8 }}
            src={require("../assets/sfScreenIssueListEN.png")}
            alt="Display requirement"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          The higher is music note, the better is team DOA (Degree of Achievement). sf2Pro calculates DOAs on rules
          which are defined by Admin in Studio Config.
          <br />
          <br />
          Each member could be evaluated by external expert (called as Guest in sf2Pro) as well as internal SME. Fair
          feedbacks per evaluators would be surely beneficial to team growth.
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">Scenario 6: Studio Management</h3>
          <p className="secDescriptionPnLeft">
            sf2Pro provides team safe and isolated digital spaces.
            <br />
            <br />
            Team members could join one or more studios. Studio Admin could invite new members, close studios or
            transfer studio balances. Authorized users could post team notices or newsletters.
          </p>
        </div>
        <img
          style={{ width: "90%", marginTop: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenRoomListEN.png")}
          alt="Studio list"
        ></img>
      </div>

      <div className="pnWrapperRow">
        <img
          style={{ width: "42%", borderRadius: 16 }}
          src={require("../assets/sfScreenMyNetworkApnEN.png")}
          alt="My network"
        ></img>
        <div style={{ width: "53%" }}>
          <h3 className="secTitlePnRight" style={{ textAlign: "right" }}>
            Network is Your Fortune
          </h3>
          <p className="secDescriptionPnRight" style={{ textAlign: "right" }}>
            sf2Pro helps you maintain your networks. The network nodes include studio members who ever worked as your
            demand/supply parties, evaluators/evaluatees or risk/objective sharers.
          </p>
        </div>
      </div>

      <div className="pnWrapperRowRight">
        <p className="pcDirect" onClick={() => props.goResource()}>
          Register sf2Pro
        </p>
      </div>
    </div>
  );
}
