import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePcPriceEN(props) {
  return (
    <div className="pcBody">
      <Helmet>
        <title>sf2Pro - Enterprise-level Service at Consumer Grade Price</title>
        <meta
          name="description"
          content="sf2Pro classifies studios with three types: Free, Standard and VIP; sf2Pro service is subscription-based, users could pay or renew studios in man-days"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,Yige"
        />
      </Helmet>
      <div className="pcPriceSection0">
        <h3 className="secTitle">sf2Pro Pricing</h3>
        <p className="secDescription">Enterprise-level Service at Consumer Grade Price</p>
      </div>

      <div className="pcPriceSection1">
        <div className="pcDescPrice1a">
          <h3 className="secTitle">Studio Type</h3>
          <p className="secDescription">
            sf2Pro studios consist of three types: Free, Standard and VIP.
            <br />
            Studio Admin, i.e. initial studio registrant, is responsbile for operating and closing studios. &nbsp;
            <a
              className="pcDirectInline"
              href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerStudioTypeEN.pdf"}
              target={"_blank"}
              rel="noreferrer"
            >
              Learn Studio Type
            </a>
          </p>
        </div>

        <div className="pcPriceSubSection">
          <img className="pcPicPrice1a" src={require("../assets/meetingrmS.jpg")} alt="Online studio"></img>
          <div className="pcDescPrice1b">
            <div className="pcDescPrice1c">
              <h3 className="secTitleS">Free Studio</h3>
              <br />
              <h3 className="secTitleS">Free</h3>
              <br />
              <p className="secDescriptionScenter">
                90 Man-days free
                <br />
                Storage on actual
                <br />
                SMS on actual
                <br />
                <br />
              </p>
            </div>
            <div className="pcDescPrice1c" style={{ backgroundColor: "#DAE7FC" }}>
              <h3 className="secTitleS">Standard Studio</h3>
              <br />
              <h3 className="secTitleS">0.00 Yuan/Man-day</h3>
              <br />
              <p className="secDescriptionScenter">
                Paid on Man-days
                <br />
                200 G-days storage free
                <br />
                50 PC SMS free
                <br />
                <br />
              </p>
            </div>
            <div className="pcDescPrice1c">
              <p className="roomTag">SP</p>
              <h3 className="secTitleS">VIP Studio</h3>
              <br />
              <h3 className="secTitleS">0.00 Yuan/Man-day (900+)</h3>
              <br />
              <p className="secDescriptionScenter">
                Paid with VIP support
                <br />
                900 G-days storage free
                <br />
                180 PC SMS free
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="pcPriceSection2">
        <div className="pcDescPrice2a">
          <h3 className="secTitle">Man-day Consumption Rules</h3>
          <p className="secDescription">
            sf2Pro service is subscription-based. Users could pay or renew studio in Man-days.
            <br />
            Studio Admin could suspend studios so that studio chargings are suspended as well. <br />
            For more details, please refer to &nbsp;
            <a
              className="pcDirectInline"
              href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerStudioPricingEN.pdf"}
              target={"_blank"}
              rel="noreferrer"
            >
              Learn sf2Pro Pricing
            </a>
          </p>
        </div>

        <img className="pcPicPrice2a" src={require("../assets/sfScreenInvoiceEN.png")} alt="Pricing"></img>
      </div>

      <div className="pcPriceSection3">
        <div className="pcDescPrice3a">
          <h3 className="secTitle">Invoice and Balance Transfer</h3>
          <p className="secDescription">
            Studio Admin could transfer Man-days to other studios as long as current studio balance is positive.
            <br />
            Studio Admin could renew studio before the studio balance is less than zero, or close it when not required
            anymore.
            <br />
            <br />
            sf2Pro supports e-invoice and refund with justified reasons.
          </p>
        </div>

        <img className="pcPicPrice3a" src={require("../assets/sfScreenAccTransferEN.png")} alt="Pricing"></img>
      </div>
    </div>
  );
}
