import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnPrice(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>sf2Pro - Enterprise-level Service at Consumer Grade Price</title>
        <meta
          name="description"
          content="sf2Pro classifies studios with three types: Free, Standard and VIP; sf2Pro service is subscription-based, users could pay or renew studios in man-days"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,Yige"
        />
      </Helmet>
      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">sf2Pro Pricing</h3>
          <p className="secDescriptionPn">
            Enterprise-level Service at Consumer Grade Price
            <br />
            <br />
            <br />
          </p>
          <h3 className="secTitlePn">Studio Type</h3>
          <p className="secDescriptionPn">
            sf2Pro studios consist of three types: Free, Standard and VIP.
            <br />
            Studio Admin, i.e. initial studio registrant, is responsbile for operating and closing studios. &nbsp;
          </p>
          <img className="pnImageInSection" src={require("../assets/meetingrmS.jpg")} alt="Online studio"></img>
        </div>
      </div>

      <div className="pnPriceSubSection">
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">Free Studio</h3>
          <h3 className="secTitleS">Free</h3>
          <p className="secDescriptionScenter">
            90 Man-days free
            <br />
            Storage on actual
            <br />
            SMS on actual
            <br />
            <br />
          </p>
        </div>
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">Standard Studio</h3>
          <h3 className="secTitleS">0.00 Yuan/Man-day</h3>
          <p className="secDescriptionScenter">
            Paid on Man-days
            <br />
            200 G-days storage free
            <br />
            50 PC SMS free
            <br />
            <br />
          </p>
        </div>
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">VIP Studio</h3>
          <h3 className="secTitleS">0.00 Yuan/Man-day (900+)</h3>
          <p className="secDescriptionScenter">
            Paid with VIP support
            <br />
            900 G-days storage free
            <br />
            180 PC SMS free
            <br />
            <br />
          </p>
        </div>

        <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 32 }}>
          For more details, please refer to &nbsp;
          <a
            className="pnDirectInline"
            href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerStudioTypeEN.pdf"}
            target={"_blank"}
            rel="noreferrer"
          >
            <br />
            Learn Studio Type
          </a>
        </p>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Man-day Consumption Rules</h3>
          <p className="secDescriptionPn">
            sf2Pro service is subscription-based. Users could pay or renew studio in Man-days.
            <br />
            <br />
            Studio Admin could suspend studios so that studio chargings are suspended as well.
          </p>
          <img className="pnImageInSection" src={require("../assets/sfScreenInvoiceEN.png")} alt="Pricing"></img>
          <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 16 }}>
            For more details, please refer to &nbsp;
            <a
              className="pnDirectInline"
              href={"https://sf-prd-public-1305246458.cos.ap-shanghai.myqcloud.com/files/SunflowerStudioPricingEN.pdf"}
              target={"_blank"}
              rel="noreferrer"
            >
              Learn sf2Pro Pricing
            </a>
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Invoice and Balance Transfer</h3>
          <p className="secDescriptionPn">
            Studio Admin could transfer Man-days to other studios as long as current studio balance is positive.
            <br />
            <br />
            Studio Admin could renew studio before the studio balance is less than zero, or close it when not required
            anymore.
          </p>
          <img className="pnImageInSection" src={require("../assets/sfScreenAccTransferEN.png")} alt="Pricing"></img>
          <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 16 }}>
            sf2Pro supports e-invoice and refund with justified reasons.
          </p>
        </div>
      </div>
    </div>
  );
}
